const flatten = require("flat");
const messages = {
  GENERAL: {
    SAVE: "GUARDAR",
    EDIT: "EDITAR",
    UPDATE: "ACTUALIZAR",
    DELETE: "ELIMINAR",
    RESET: "REINICIAR",
    ACTIONS: "Acciones",
    TOTAL: "TOTAL",
    ID: "ID",
    PLACEHOLDER_SEARCH: "Buscar...",
    REQUIRED_FIELD: "Campo requerido",
    REQUIRED_DIFFERENT_ANY: "Este campo no puede tener sólo objetos tipo Any",
    CANCEL: "Cancelar",
    OK: "Ok",
    WARNING: "Advertencia",
    INFO: "Información",
    CONFIRM_DELETE: "¿Está seguro de que quiere eliminar esto?",
    EDIT_SMALL: "Editar",
    DELETE_SMALL: "Eliminar",
    DELETE_COMPLETELY: "Eliminar completamente",
    BROWSE: "Seleccionar",
    EDIT_OBJECT: "Editar objeto",
    WHERE_USED: "Dónde está siendo usado",
    NOT_IN_USE: "No está en uso",
    SAVE_SMALL: "Guardar",
    NO_DATA: "No hay información para mostrar",
    SWW: "Algo salió mal",
    ERROR: "Error",
    SUCCESS: "Correcto",
    SAVE_SUCCESS: "Guardado correctamente",
    UPDATE_SUCCESS: "Actualizado correctamente",
    DELETE_SUCCESS: "Borrado correctamente",
    MIN_LENGTH: "La longitud mínima debe ser {length}",
    CURRENTLY_NOT_AVAILABLE: "Actualmente no disponible",
    PUBLISHING_CHANGES: "Publicando los cambios, espere ...",
    VERIFYING_CHANGES: "Verificando los cambios, espere ...",
    INSTALLING_CHANGES: "Instalando los cambios, espere ...",
    DISCARDING_CHANGES: "Descartando los cambios, espere ...",
    BATCH_PROCESSING: "Procesando cambios masivos...",
    FAILED_TO_PUBLISH: "No se pudo publicar la política.",
    FAILED_TO_INSTALL: "No se pudo instalar la política.",
    LOCKED: "Bloqueado",
    LOADING: "Cargando",
    INVALID_OBJECT_NAME: "Nombre de objeto inválido",
    INVALID_IP_ADDRESS: "IP Address inválida",
    INVALID_EMAIL: "Email inválido",
    REFRESH: "Actualizar",
    DISCARD_SESSION:
      "¿Está seguro? Esto descartará los cambios de otros usuarios",
    FUTURE_RELEASE: "Estará disponible en una versión futura.",
    AVAILABLE: "Disponible",
    UNAVAILABLE: "No disponible",
    ACTIVE: "Activo",
    ABOUT_TO_EXPIRE: "Próximo a caducar",
    EXPIRED: "Caducado",
    ALL: "Todos",
    ADD: "Añadir",
    SYNC: "Sincronizar",
    SELECT: "Seleccionar opción",
    SORT: "Ordenar",
    UPLOAD: "Subir fichero",
    IP_ADDRESS: "Dirección IP",
    IP_ADDRESS_V4_V6: "Dirección IP (IPv4/IPv6)",
    RECEIVE_MAILS: "Recibir correos",
    REVERT_CHANGES: "Revertiendo cambios",
    REVERT_SUCCESS: "Cambios revertidos con éxito",
    HAS_SPECIAL_CHARACTERS: "No debe contener ningún carácter especial",
    CONFIRM_LOGIN: "Ya hay otro usuario contectado con estas credenciales. ¿Desea tomar control de la sesión o conectarse en modo solo lectura?",
    TAKE_OVER: "Tomar control",
    READ_ONLY_MODE: "Modo solo lectura",
    DEFAULT: "Por defecto",
    ANY: "Any"
  },
  SIDEBAR: {
    DASHBOARD: "Dashboard",
    TUNNELS: "Túneles",
    ACCESS_CONTROL: "Control de acceso",
    THREAT_PEVENTION: "Prevención de amenazas",
    SSL_INSPECTION: "Inspección SSL",
    IDENTITY_AWARENESS: "Mecanismo de autenticación",
    ACCOUNTS: "Cuentas",
    LOGS: "Logs e Informes",
    TENANTS: "Tenants",
    ADMINISTRATORS: "Administradores",
    ADMIN: "Administración",
    FIREWALLS_CLUSTERS: "Firewalls y Clústers",
    LICENSES: "Licencias",
    MAINTENANCE: "Mantenimiento"
  },
  NAVBAR: {
    LOGOUT: "Cerrar sesión",
    OBJECT_EXPLORER: "Explorador de objetos",
    CHANGES: "Cambios",
    INSTALL_POLICY: "Instalar política",
    DISCARD_CHANGES: "Descartar los cambios",
    CONFIRM: "Confirmar",
    CONFIRM_INSTALL: "¿Está seguro/a de que quiere instalar la política?",
    CONFIRM_DISCARD: "¿Está seguro/a de que quiere descartar los cambios?",
    MANAGE_POLICIES: "Gestionar políticas",
    POLICY_EXPLORER: "Explorador de políticas"
  },
  OBJECT_EXPLORER: {
    NETWORK_GROUPS: "Objetos de red",
    NETWORK_ELEMENTS: "Elementos de Red",
    NETWORKS: "Redes",
    NETWORK_GROUP: "Grupos de red",
    CLIENT_NETWORK_GROUP: "Clientes",
    ACCESS_ROLES: "Roles de acceso",
    SERVICES: "Servicios",
    APPLICATIONS: "Aplicaciones",
    APPLICATIONS_DEFAULT: "Aplicaciones predefinidas",
    APPLICATIONS_CUSTOM: "Aplicaciones personalizadas",
    APPLICATION_GROUPS: "Grupos de aplicaciones",
    CATEGORIES: "Categorías",
    DOMAIN: "Hostnames/FQDNs",
    PATTERNS: "DLP",
    POLICIES: "Políticas",
    UPDATABLE_OBJECTS: "Objetos actualizables",
    PATTERNS_CONTENT: "Contenido",
    PATTERNS_FILE: "Fichero",
    ADDRESS_RANGE: "Rango de direcciones",
  },
  SERVICE_OBJECTS: {
    LOAD_ERROR: "Error al cargar objetos de tipo Servicio",
    SELECT_ERROR: "Error al seleccionar objeto de tipo Servicio",
  },
  DASHBOARD: {
    TUNNELS: "Información de los tuneles",
    LOGS: "Últimos logs",
    TOPS: "Tops",
    REVISIONS: "Últimos cambios",
    TOTAL: "Total",
    FULL_UP: "Completamente levantado",
    UP: "Levantado",
    DOWN: "Caído",
  },
  DOMAIN: {
    ADD_DOMAIN: "Agregar Hostname/FQDN",
    EDIT_DOMAIN: "Editar Hostname/FQDN",
    ENTER_OBJECT_NAME: "Introduzca el nombre del objeto",
    ENTER_OBJECT_COMMENT: "Introduzca comentario de objeto",
    CONTENT: "Tratar como FQDN :",
    FQDN: "FQDN (Fully qualified domain name)",
    INVALID_OBJECT: "Objeto no válido",
    VALIDATION: 'Siempre debe comenzar con un punto (".")',
    LOAD_ERROR: "Error al cargar Hostnames/FQDNs",
    SELECT_ERROR: "Error al seleccionar Hostname/FQDN",
    ALREADY_IN_USE:
      "El objeto no se puede eliminar porque se está usando en otros lugares",
      HELP_TITLE: "Ayuda de Hostname/FQDN",
  },
  NETWORK_GROUPS: {
    NAME: "Nombre",
    IPV4_ADDRESS: "Dirección IPv4",
    MASK: "Máscara",
    IPV6_ADDRESS: "Dirección IPv6",
    MASK6: "Máscara IPv6",
    NAT_PROPERTIES: "Propiedades NAT",
    COMMENTS: "Comentarios",
    SEARCH: "Buscar...",
    NETWORK: "Red",
    GROUPS: "Grupos",
    ADD_NETWORK: "Agregar red",
    NEW_NETWORK: "Nueva red",
    EDIT_NETWORK: "Editar red",
    ADD_CLIENT: "Nuevo cliente",
    EDIT_CLIENT: "Editar cliente",
    ENTER_OBJECT_NAME: "Introduzca el nombre del objeto",
    ENTER_OBJECT_COMMENT: "Introduzca comentario de objeto",
    GENERAL: "General",
    NAT: "NAT",
    TYPE: "Tipo",
    IPV4: "IPv4",
    NETWORK_ADDRESS: "Dirección de red",
    NET_MASK: "Máscara de red",
    BROADCAST_ADDRESS: "Dirección de Broadcast",
    INCLUDED: "Incluida",
    NOT_INCLUDED: "No incluida",
    IPV6: "IPv6",
    PREFIX: "Prefijo",
    ADD_TAG: "Añadir etiqueta",
    NETWORK_LIST: "Lista de redes",
    NETWORK_IP_ADDRESS: "Dirección de red / IP",
    LAN: "LAN",
    NO_LAN: "No LAN",
    ADD_NETWORK_GROUP: "Agregar Grupo de Red",
    INVALID_IP_MASK: "La máscara no es válida para la IP introducida",
    EDIT_NETWORKS: "Editar redes",
    NETWORK_UPDATE_SUCCESS: "Redes actualizadas correctamente",
    GROUP_CREATED_SUCCESS: "Grupo creado correctamente",
    NETWORK_ERROR: "Error en {network_name}",
    INVALID_NETWORK: "Máscara de dirección IP de red no válida",
    NETWORK_DELETED_SUCCESS: "Red eliminada del grupo",
    REMOVE_NETWORK_FROM_GROUP: "Desasociar red del grupo",
    DETACH_GROUP: "Desasociar grupo",
    GROUP_DETACHED_SUCCESS: "Grupo desasociado correctamente",
    DETACH: "Desasociar",
    GROUP_UPDATE_SUCCESS: "El nombre del grupo se actualizó correctamente",
    GROUP_DELETE_SUCCESS: "Grupo eliminado correctamente",
    PRIVATE: "Privada",
    PRIVATE_INVALID_NETWORK:
      "Solo se permiten direcciones privadas en un grupo de direcciones privadas.",
    PUBLIC_INVALID_NETWORK:
      "Solo se permiten direcciones públicas en un grupo no privado.",
    INVALID_PUBLIC_ADDRESS:
      "Para una IP pública, la longitud de la subred solo puede estar entre 24 y 32.",
    NETWORK_IP_ADDRESS_EXISTS: "La dirección IP ya existe",
    ADD_NETWORK_OBJECT: "Agregar objeto de red",
    EDIT_NETWORK_OBJECT: "Editar objeto de red",
    NETWORK_OBJECT_CREATED_SUCCESS: "Objeto de red creado correctamente",
    NETWORK_OBJECT_CREATED_ERROR: "Error al crear objecto de Red",
    NETWORK_OBJECT_UPDATED_SUCCESS: "Objeto de Red actualizado correctamente",
    NETWORK_OBJECT_UPDATED_ERROR: "Error al actualizar objeto de Red",
    NETWORK_OBJECT_DELETED_SUCCESS: "Objeto de Red borrado correctamente",
    NETWORK_OBJECT_DELETED_ERROR: "Error al borrar objeto de Red",
    LOAD_ERROR: "Error al cargar objetos de red",
    SELECT_ERROR: "Error al seleccionar objeto de red",
    ALREADY_IN_USE:
      "El objeto no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde está siendo usado'.",
    CLIENT_LOAD_ERROR:"Error al cargar Clientes",
    CLIENT_SELECT_ERROR:"Error al seleccionar Clientes",
    CLIENT_DELETE_SUCCESS:"Cliente eliminado correctamente",
    CLIENT_CREATED_SUCCESS:"Cliente creado correctamente",
    CLIENT_UPDATED_SUCCESS:"Cliente actualizado correctamente",
    FIRST_IP_ADDRESS:"Primera dirección IP",
    LAST_IP_ADDRESS:"Última dirección IP",
    ADD_ADDRESS_RANGE_OBJECT: "Agregar objeto de rango de direcciones",
    ADDRESS_RANGE_CREATED_SUCCESS: "Rango de direcciones creado correctamente",
    ADDRESS_RANGE_UPDATED_SUCCESS: "Rango de direcciones actualizado correctamente",
    ADDRESS_RANGE_DELETED_SUCCESS: "Rango de direcciones borrado correctamente",
    ADDRESS_RANGE_LOAD_ERROR: "Error al cargar objetos de rango de direcciones",
    ADDRESS_RANGE_SELECT_ERROR: "Error al seleccionar objeto de rango de direcciones",
    INVALID_IP_ADDRESS: "Dirección IP no válida",
    NATED_IP:"Red Nat/ IP",
    NATED_IPV4:"Nat IPv4",
    ORIGINAL_IP: "Red Original / IP",
    ORIGINAL_IPV4: "IPv4 Original"
  },
  APPLICATIONS_CATEGORIES: {
    NAME: "Nombre",
    COMMENTS: "Comentarios",
    TAGS: "Etiquetas",
    MODIFIER: "Modificador",
    LAST_MODIFIED: "Última modificación",
    LOAD_ERROR: "Error al cargar Categorías de Aplicaciones",
  },
  APPLICATIONS: {
    CUSTOM: "Aplicaciones Personalizadas / URLs",
    URL_LIST: "Lista de URLs",
    NAME: "Nombre",
    EDIT_APPLICATION: "Editar Aplicación",
    CREATE_APPLICATION: "Crear Aplicación",
    APPLICATION_CREATE_SUCCESS: "Aplicación creada corretamente",
    APPLICATION_DELETE_SUCCESS: "Aplicación borrada corretamente",
    APPLICATION_UPDATE_SUCCESS: "Aplicación actualizada corretamente",
    URLS_AS: "Las URLs se definen como:",
    WILDCARD: "Wildcards",
    REGEX: "Expersiones regulares",
    DEFAULT: "Aplicaciones Predefinidas",
    LOAD_ERROR: "Error al cargar Aplicaciones",
    GROUPS: "Grupos de Aplicaciones",
    NEW_GROUP: "Añadir group",
    EDIT_GROUP: "Editar group",
    APPLICATIONS_LIST: "Lista de aplicaciones",
    ENTER_GROUP_NAME: "Introduzca nombre de grupo",
    GROUP_CREATED_SUCCESS: "Grupo de usuarios creado correctamente",
    NO_APPLICATIONS: "Sin aplicaciones",
    APPLICATION_UPDATED_SUCCESS: "Grupo de usuarios actualizado correctamente",
    GROUP_DELETED_SUCCESS: "Grupo de usuarios borrado correctamente",
  },
  SERVICES: {
    NAME: "Nombre",
    PORT: "Puerto",
    PROGRAM_NUMBER: "Número de programa",
    ICMP_TYPE: "Tipo de ICMP",
    ICMP_CODE: "Código ICMP",
    COMMENTS: "Comentarios",
    ADD_SERVICE: "Agregar servicio",
    NEW_TCP_SERVICE: "Nuevo servicio TCP",
    NEW_UDP_SERVICE: "Nuevo servicio UDP",
    NEW_ICMP_SERVICE: "Nuevo servicio ICMP",
    NEW_OTHER_SERVICE: "Nuevo servicio OTRO",
    EDIT_TCP_SERVICE: "Editar servicio TCP",
    EDIT_UDP_SERVICE: "Editar servicio UDP",
    EDIT_ICMP_SERVICE: "Editar servicio ICMP",
    EDIT_OTHER_SERVICE: "Editar otro servicio",
    GENERAL: "GENERAL",
    ADVANCED: "Avanzado",
    PROTOCOL: "Protocolo",
    MATCH_BY: "IDENTIFICAR POR",
    ADD_TAG: "Añadir etiqueta",
    TCP_SERVICE: "Servicio TCP",
    UDP_SERVICE: "Servicio UDP",
    ICMP_SERVICE: "Servicio ICMP",
    OTHER_SERVICE: "Otros Servicios",
    NO_ITEM_SELECTED: "Ningún elemento seleccionado",
    IP_PROTOCOL: "Protocolo IP",
    CONNECTION_OPEN:
      "Mantenga la conexión abierta después de que se haya instalado la política.",
    INVALID_PORT: "Puerto inválido",
    INVALID_PROTOCOL: "Protocolo inválido",
    HAS_WHITE_SPACE: "No debe contener ningún espacio en blanco",
  },
  PATTERNS: {
    PATTERNS: "Expresiones regulares",
    URL: "DLP",
    FILE: "Fichero DLP",
    NAME: "Name",
    CREATE_PATTERN: "Add pattern",
    EDIT_PATTERN: "Edit pattern",
    DELETE_PATTERN: "Delete pattern",
    REGEX_SYNTAX: "Regular Expression syntax",
    REGEX_SYNTAX_TITLE: "Regular expressions syntax guideline",
    PATTERN_CREATE_SUCCESS: "Firma creada corretamente",
    PATTERN_CREATE_ERROR: "Error al crear Firma",
    PATTERN_UPDATE_SUCCESS: "Firma actualizada corretamente",
    PATTERN_UPDATE_ERROR: "Error al actualizar Firma",
    PATTERN_DELETE_SUCCESS: "Firma borrada corretamente",
    PATTERN_DELETE_ERROR: "Error al borrar Firma",
    LOAD_ERROR: "Error al cargar Firmas",
  },
  TUNNELS: {
    TUNNELS: "Túneles",
    NAME: "Nombre",
    LOCATION: "Localización",
    IP_HOSTNAME: "Hostname/IP",
    IP: "IP",
    NETWORKS: "Redes",
    HOST_ADDRESS: "Dirección del hostname",
    HOSTNAME: "Hostname",
    CHECK_ADDRESS: "COMPROBAR IP",
    HOSTNAME_NOT_RESOLVED: "IP no resuelta para el hostname actual",
    STATUS: "Estado",
    ADD_NEW_TUNNEL: "Agregar nuevo túnel",
    GENERAL: "GENERAL",
    SITE_ADDRESS: "Dirección del sitio",
    CONNECTION_DETAILS: "DETALLES DE CONEXIÓN",
    EXTERNAL_IP_ADDRESSES: "Direcciones IP",
    DYNAMIC_IP_ADDRESS: "IP dinámica externa",
    INTERNAL_DYNAMIC_IP_ADDRESS: "IP dinámica interna",
    STATIC_IP_ADDRESS: "Estática",
    AUTHENTICATION: "AUTENTICACIÓN",
    SELECT_YOUR_AUTH_OPTION: "Seleccione su opción de autenticación",
    SHARED_KEY: "Clave compartida",
    UPLOAD_CERTIFICATE: "Cargar certificado",
    SELECT_CERTIFICATE: "Seleccionar certificado",
    INTERNAL_SUB_NETWORKS: "SUB-REDES INTERNAS",
    ADD_SUB_NETWORKS: "Agregar subredes",
    INVALID_HOST: "Host inválido",
    UPDATE_TUNNEL: "Actualizar Túnel",
    CREATE_SUCCESS: "Túnel creado correctamente",
    DELETE_SUCCESS: "Túnel eliminado correctamente",
    UPDATE_SUCCESS: "Túnel actualizado correctamente",
    CONFIRM_RESET:
      "¿Está seguro de que quiere restablecer la clave compartida?",
    VPN_LOCKED: "La sección túneles está bloqueada por otro usuario",
    SHARED_KEY_LETTER_COUNT: "Debe contener al menos 4 caracteres diferentes",
    PUBLISH_CREATE: "Guardar",
    PENDING_VALIDATION:
      "Antes de crear un túnel, instale o descarte los cambios pendientes.",
    LOAD_ERROR: "Error al cargar Túneles",
    UPLOAD_TUNNELS: "Cargar masiva de túneles",
    WORK_IN_PROGRESS: "Trabajo en progreso",
    FILE: "Archivo",
    SELECT_FILE: "Seleccionar archivo",
    UPLOAD: "Subir",
    BATCH_SUCCESS: "Túneles creados con éxito",
    BATCH_ERROR: "Error al cargar el fichero",
    BATCH_WARNING: "Proceso finalizado con algunos errores",
    BATCH_ERROR_MODAL_TITLE: "La creación masiva de túneles finalizó con algunos errores",
    FILE_HELP_TITTLE: "Ayuda de fichero de carga masiva",
    HELP_TITTLE: "Ayuda de túneles",
    FULL_UP: "Levantado (F1 & F2)",
    UP: "Levantado (F1)",
    DOWN: "Caído",
    HOST_UNAVAILABLE: "El hostname no está disponible",
    HOST_LIST_LOADING: "Esperando a que cargue la lista de hostnames disponibles",
    NO_VPN: "La política seleccionada no tiene una VPN asociada",
    CONFIRM_DELETE_BATCH: "¿Está seguro de que quiere eliminar los túneles seleccionados?",
    DELETE_BATCH_SUCCESS: "Túneles seleccionados eliminados correctamente",
    LOCAL_ID: "ID local",
    INTERNAL_DYNAMIC_IP_TOOLTIP: "La IP debe ser diferente de cualquier otra IP de tunel u objeto configurados",
    DYNDNS_LOAD_ERROR: "Error al cargar grupo dyndns",
  },
  POLICIES: {
    ACCESS_CONTROL: "Control de acceso",
    ACCESS_RULE: "Regla de acceso",
    ACTION: "Acción",
    NAME: "Nombre",
    SOURCE: "Origen",
    DESTINATION: "Destino",
    ADD_NEW_POLICY: "Agregar nueva regla",
    UPDATE_POLICY: "Actualizar regla",
    ACCEPT: "Aceptar",
    DROP: "Drop",
    ALLOW: "Permitir",
    BLOCK: "Bloquear",
    ENABLE: "Enable",
    DISABLE: "Disable",
    ORDER: "Posición",
    ORDER_ERROR: "La posición debe estar entre 1 y {orderLimit}",
    PUBLISH_SUCCESS: "Cambios publicados correctamente",
    DISCARD_SUCCESS: "Cambios descardo correctamente",
    CONFIRM_DISABLE: "¿Está seguro de que desea inhabilitar esta política?",
    CONFIRM_ENABLE: "¿Está seguro de que desea habilitar esta política?",
    CREATE_SUCCESS: "Política creada correctamente",
    DELETE_SUCCESS: "Política eliminada correctamente",
    UPDATE_SUCCESS: "Política actualizada correctamente",
    AUTHENTICATION_RULE: "Regla de autenticación",
    ADD_RULE_ABOVE: "Añadir regla encima",
    ADD_RULE_BELOW: "Añadir regla debajo",
    ADD_RULE_TOP: "Añadir regla al inicio",
    ADD_RULE_BOTTOM: "Añadir regla al final",
    MOVE_RULE_ABOVE: "Mover regla hacia arriba",
    MOVE_RULE_BELOW: "Mover regla hacia abajo",
    SELECT_HTTP_ERROR: "Incluya HTTP o HTTPS de los servicios TCP",
    RULE_LOCKED: "La política está bloqueada por otro usuario",
    LOAD_ERROR: "Error al cargar Política",
    SELECTED_POLICY: "Política Seleccionada",
    TARGETS: "Destinos",
    SOURCE_NUMBER_ERROR: "Sólo se permite un objeto de origen.",
    AUTHENTICATION_RULE_HELP_TITLE: "Ayuda de reglas de autenticación",
    HITS: "coincidencias",
    ADD_SECTION: "Añadir nueva sección",
    ADD_SUCCESS: "Sección añadida correctamente",
    DELETE_SECTION_SUCCESS: "Sección eliminada correctamente"
  },
  THREAT_PREVENTION: {
    THREAT_PREVENTION: "Prevención de amenazas",
    THREAT_RULE: "Regla de prevención de amenazas",
    PROTECTION_PROFILE: "PERFIL DE PROTECCIÓN",
    PROFILE_TECHNOLOGIES: "TECNOLOGÍAS DE PERFIL",
    SANDBOX: "Sandbox",
    THREAT_CLOUD: "Threat Cloud",
    C_C_PROTECTION: "Protección C&C",
    IPS_PROTECTION: "Protección IPS",
    IDS_PROTECTION: "Protección IDS",
    FILE_URL_PROTECTION: "Protección de archivos y URLs",
    EXPLOIT_PROTECTION: "Protección contra exploits",
    EXCEPTIONS: "Excepciones",
    NAME: "Nombre",
    SOURCE: "Origen",
    DESTINATION: "Destino",
    PROTECTION: "Protección",
    THREAT_PROTECTIONS: "Protecciones contra amenazas",
    SERVICE: "Servicio",
    ACTION: "Acción",
    STATUS: "Estado",
    ORDER: "Posición",
    ENABLE: "Habilitar",
    DISABLE: "Deshabilitar",
    CONFIRM_DISABLE: "¿Está seguro de que quiere deshabilitar esta excepción?",
    CONFIRM_ENABLE: "¿Está seguro de que quiere habilitar esta excepción?",
    ADD_RULE_ABOVE: "Añadir regla encima",
    ADD_RULE_BELOW: "Añadir regla debajo",
    ADD_RULE_TOP: "Añadir regla al principio",
    ADD_RULE_BOTTOM: "Añadir regla al final",
    MOVE_RULE_ABOVE: "Mover regla arriba",
    MOVE_RULE_BELOW: "Mover regla abajo",
    IPS_DISABLED:
      "La protección IPS/IDS está desactivada, por lo que las excepciones de amenaza no funcionarán. Puede que tenga cambios pendientes de publicar.",
    CONTENT:
      "Seguridad optimizada para los usuarios. Evita los ciberataques. Incluye protección para usuarios que navegan por la web, trabajan con aplicaciones de escritorio, acceden a aplicaciones corporativas, comparten archivos a través de FTP o usan otros protocolos de red. Este es el perfil recomendado para múltiples protecciones en el tráfico entre usuarios y aplicaciones.",
    ADD_EXCEPTION: "Añadir excepción",
    UPDATE_EXCEPTION: "Actualizar excepción",
    PROFILE_UPDATE_SUCCESS: "Perfiles de amenaza guardados.",
    EXCEPTION_CREATE_SUCCESS: "Excepción de amenaza creada correctamente",
    EXCEPTION_UPDATE_SUCCESS: "Excepción de amenaza actualizada correctamente",
    EXCEPTION_DELETE_SUCCESS: "Excepción de amenaza borrada correctamente",
    EXCEPTION_ORDER_ERROR: "La posición debe ser entre 1 y {orderLimit}",
    GLOBAL_EXCEPTIONS_LOCKED:
      "La tabla de excepciones está bloqueada por otro usuario.",
    THREAT_TASK_FINISHED: "Actualización de perfiles de protección terminada",
    THREAT_TASK_ERROR:
      "Ha habido un error mientras se realizaba la tarea de actualización de perfiles de protección",
    PROFILES_UPDATING:
      "Actualizando perfiles de protección. Por favor, espere.",
    LOAD_ERROR_EXCEPTIONS: "Error al cargar Excepciones de Amenazas",
    LOAD_ERROR_PROFILES: "Error al cargar Perfiles de Protección",
    PROFILE_LOCKED: "El perfil de amenaza está bloqueado por otro usuario",
    DEFAULT_PROFILE: "Predeterminado",
    THREAT_PROFILE_CREATION_ERROR: "Un perfil de protección a sido cambiado por otro usuario, publica o descarta dichos cambios antes de guardar los tuyos. \nPuedes descartar dichos cambios en Administración/Sesiones",
  },
  SSL_INSPECTION: {
    SSL_INSPECTION: "Inspección SSL",
    SSL_EXCEPTION: "Excepciones SSL",
    ACTION: "Acción",
    NAME: "Nombre",
    SOURCE: "Origen",
    DESTINATION: "Destino",
    SITE_CATEGORY: "Categoría del sitio",
    POSITION: "Posición",
    SELECT_YOUR_SSL: "SELECCIONE SU MODO DE INSPECCIÓN SSL",
    SSL_INSPECTION_MODE: "Modo de inspección SSL",
    BASIC: "Básico",
    FULL: "Completo",
    DOWNLOAD_FULL: "Descargar certificado",
    DOWNLOAD_CERTIFICATE: "DESCARGAR CERTIFICADO",
    DOWNLOADED_CERTIFICATE: "Certificado descargado",
    DOWNLOAD_FAILED:
      "Error al descargar, actualice la página y vuelva a intentarlo.",
    DOWNLOADING: "Descargando",
    UPLOAD_CERTIFICATE: "Cargar Certificado",
    UPLOAD_YOUR_OWN_CERTIFICATE: "Sube tu propio certificado",
    EXCEPTIONS: "EXCEPCIONES",
    DO_NOT_INSPECT: "No inspeccionar los siguientes orígenes",
    ADD_SSLINSPECTION: "Añadir excepción",
    AUTHENTICATION_RULE: "Regla de autenticación",
    UPDATE_SSLINSPECTION: "Actualizar excepción",
    ACTIVATE_FULL: "No inspeccione los siguientes destinos",
    CONFIRM_DISABLE:
      "¿Está seguro de que desea deshabilitar esta inspección SSL?",
    CONFIRM_ENABLE: "¿Está seguro de que desea habilitar esta inspección SSL?",
    UPDATE_SUCCESS: "Excepción actualizada correctamente",
    CREATE_SUCCESS: "Excepción creada correctamente",
    RULE_LOCKED: "La tabla de excepciones está bloqueada por otra usuario.",
    ADD_RULE_ABOVE: "Añadir regla encima",
    ADD_RULE_BELOW: "Añadir regla debajo",
    ADD_RULE_TOP: "Añadir regla al principio",
    ADD_RULE_BOTTOM: "Añadir regla al final",
    ENABLE: "Habilitar",
    DISABLE: "Deshabilitar",
    LOAD_ERROR: "Error al cargar Inspecciones SSL",
    SSL_MODE_CREATE_SUCCESS: "Modo de inspección SSL guardado correctamente",
  },
  IDENTITY_AWARENESS: {
    IDENTITY_AWARENESS: "Mecanismo de autenticación",
    SELECT_YOUR_IDENTITY_PROVIDER: "SELECCIONA A TU PROVEEDOR DE IDENTIDAD",
    PROVIDER: "Proveedor",
    VERIFY_DOMAIN: "VERIFICAR DOMINIO",
    DOMAIN: "Dominio",
    IDENTITY_AWARENESS_SECTION_HEADING: "Mecanismo de autenticación",
    ENABLE_IDENTITY_AWARENESS_FOR_USERS:
      "Habilitar mecanismo de autenticación para los usuarios",
    ENABLE_IDENTITY_AWARENESS_FOR_SITES:
      "Habilitar mecanismo de autenticación para sitios",
    BYPASS_AUTHENTICATION_FOR_THESE_SOURCES:
      "Omitir la autenticación para estos orígenes",
    PLACEHOLDER_DOMAIN: "Por ejemplo: miempresa.com",
  },
  ACCOUNTS: {
    ACCOUNTS: "Cuentas",
    TENANTS: "Tenants",
    NAME: "Cliente",
    USER_NAME: "Nombre de usuario",
    SERVER_HOST: "Host del servidor",
    PRIVATE_SERVER_IP: "IP privada del servidor",
    ADD_ACCOUNT: "Añadir cuenta",
    EDIT_ACCOUNT: "Editar cuenta",
    PASSWORD_RESET: "Restablecer la contraseña",
    NEW_PASSWORD: "Nueva contraseña",
    PASSWORD: "Contraseña",
    REQUIRED_SERVER_HOST: "Host de servidor no válido",
    REQUIRED_PRIVATE_SERVER_IP: "IP privada del servidor no válida",
    CONFIRM_RESET: "¿Está seguro de que quiere restablecer la contraseña?",
    CLEANUP: "Limpiar las sesiones",
    SESSIONS_CLEANED: "Sesiones limpiadas",
    CLEAN_ERROR: "Error al limpiar sesiones",
    MULTIPLE_FIREWALL: "Cortafuegos múltiple",
    ADD_ACCOUNT_SUCCESS: "Cliente añadido correctamente",
    ADD_ACCOUNT_ERROR: "Error al crear cliente",
    EDIT_ACCOUNT_SUCCESS: "Cliente actualizado correctamente",
    EDIT_ACCOUNT_ERROR: "Error al actualizar cliente",
    LOAD_ERROR: "Error al cargar clientes",
    CONFIRM_DELETE: "¿Está seguro de que quiere eliminar la cuenta? Introduzca el nombre de la cuenta para confirmar: ",
    DELETE_SUCCESS: "Cuenta eliminada correctamente",
    CONFIRM_ERROR: "The confirmación es incorrecta",
    DATACENTER1: "IP Túnel 1",
    DATACENTER2: "IP Túnel 2",
    REQUIRED_DATACENTER: "Se requiere la IP de uno de los Datacenters",
    DATACENTER_IP_INVALID: "La IP de un Datacenter no es válida",
    ACCOUNT_TYPE: "Tipo de cuenta",
    STANDARD: "Estándar",
    CARRIER: "Carrier",
    CARRIER_CGNAT: "CGNAT - Carrier",
    STANDARD_CGNAT: "CGNAT - Estándar",
    CONTRACT_TB: "TB del contrato",
    CONTRACT_TB_INVALID: "Los TB del contrato no son válidos",
    ADMINISTRATORS: "Administradores",
    OSDX_HOST: "Osdx host",
    OSDX_PUBLIC: "IP Pública",
    OSDX_PRIVATE: "IP Privada",
    OSDX_GATEWAY: "IP Gateway",
    OSDX_TUNNEL: "IP Túneles",
    REQUIRED_OSDX_HOST: "OSDx Host requerido",
    OSDX_HOST_INVALID: "OSDx Host inválido",
    REQUIRED_OSDX_PRIVATE_IP: "IP privada del OSDx requerido",
    OSDX_PRIVATE_IP_INVALID: "IP privada del OSDx inválido",
    REQUIRED_OSDX_PRIVATE_IP_GATEWAY: "Gateway de la IP privada del OSDx requerida",
    OSDX_PRIVATE_IP_GATEWAY_INVALID: "Gateway de la IP privada del OSDx inválida",
    REQUIRED_OSDX_PRIVATE_TUNNEL_IP: "IP de túneles del OSDx requerida",
    OSDX_PRIVATE_TUNNEL_IP_INVALID: "IP de túneles del OSDx inválida",
    LOG_LEVEL: "Nivel de logs",
    STANDARD_LOG: "Log estandar",
    DETAILED_LOG: "Log detallado",
  },
  USERS: {
    USERS: "Usuarios",
    EMAIL: "Email",
    FIRST_NAME: "Nombre",
    LAST_NAME: "Apellido",
    READ_ONLY: "Sólo lectura",
    PRIVILEGES: "Permisos",
    YES: "Sí",
    NO: "No",
    RO: "RO",
    RW: "RW",
    NEW_USER: "Añadir nuevo usuario",
    UPDATE_USER: "Actualizar usuario",
    PASSWORD: "Contraseña",
    CREATE_SUCCESS: "Usuario creado correctamente",
    UPDATE_SUCCESS: "Usuario actualizado correctamente",
    RESET_PASSWORD_MAIL_SUCCESS: "Mail para restablecer contraseña enviado correctamente",
    RESET_PASSWORD_SUCCESS: "Contraseña modificada correctamente",
    REPEAT_PASSWORD: "Repetir contraseña",
    RESET_PASSWORD: "Reinicia la contraseña de tu cuenta",
    IS_MASTER: "Usuario maestro",
    CONFIRM_MASTER_CHANGE: "¿Está seguro de que desea cambiar el usuario maestro?",
    TENANT: "Tenant",
    SUPERADMIN: "Superadmin",
    IMPERSONATE: "Suplantar",
    CONFIRM_IMPERSONATE: "¿Está seguro de que desea identificarse como otro usuario?",
    API_KEY: "Api key",
    CONFIRM_REFRESH_API_KEY: "¿Está seguro de que desea refrescar la api key?",
    REFRESH_API_KEY_SUCCESS: "La api key fue refrescada correctamente",
    REFRESH_API_KEY: "Refrescar la api key",
    ADMIN: "Administrador",
    ADMIN_RO: "Administrador de sólo lectura",
    END_USER: "Usuario final",
    END_USER_RO: "Sólo lectura",
    CLIENT: "Cliente",
    ROLE: "Rol"
  },
  NETWORK_USERS: {
    NAME: "Nombre",
    USERS: "Usuarios",
    USERNAME: "Nombre de usuario",
    USERNAME_EXAMPLE: "p.ej: username@dominio.com",
    EMAIL: "Email",
    COMMENTS: "Comentarios",
    NEW_USER: "Añadir Usuario",
    EDIT_USER: "Editar Usuario",
    GROUPS: "Grupos",
    PASSWORD: "Contraseña",
    AUTH_METHOD: "Método de autenticación",
    DEFAULT_AUTH_METHOD: "Check Point password",
    USER_CREATED_SUCCESS: "Usuario creado correctamente",
    USER_CREATED_ERROR: "Error al crear usuario",
    USER_UPDATED_SUCCESS: "Usuario actualizado correctamente",
    USER_UPDATED_ERROR: "Error al actualizar usuario",
    USER_DELETED_SUCCESS: "Usuario borrado correctamente",
    USER_DELETED_ERROR: "Error al borrar usuario",
    PASSWORD_LENGTH: "La contraseña debe tener entre 4 y 8 caracteres",
    PASSWORD_LENGTH_EXT: "La contraseña debe tener entre 8 y 32 caracteres",
    CONFIRM_RESET: "¿Está seguro de que desea restablecer la contraseña?",
    LOAD_ERROR: "Error al cargar Usuarios de Red",
    SELECT_ERROR: "Error al seleccionar Usuario de Red",
    UPLOAD_USERS: "Subir usuarios",
    FILE: "Archivo",
    SELECT_FILE: "Seleccione Archivo",
    UPLOAD: "Subir",
    USERS_CREATED_SUCCESS: "Los usuarios de CSV se crearon correctamente",
    USER_TASK_ERROR: "Error al crear el usuario de CSV. Inténtalo de nuevo",
    CSV_PROCESSING:
      "CSV subido correctamente. Espere, le notificaremos cuando finalice la creación de usuarios",
    ERROR_SOME_USER: "Error al crear los siguientes usuarios",
    WORK_IN_PROGRESS: "Trabajo en progreso",
    CONFIRM_DELETE: "¿Está seguro de que desea eliminar este usuario?",
    DELETE_SUCCESS: "Usuario eliminado exitosamente",
    USERS_BATCH_SUCCESS: "Usuarios creados correctamente",
    FILE_HELP_TITTLE: "Ayuda de fichero de Directorio Activo",
    REPEAT_PASSWORD_NOT_EQUAL: "Las contraseñas no coinciden",
    RESET_PASSWORD_MAIL_SUCCESS: "La contraseña sera restablecida cuando se apliquen los cambios",
    RESET_PASSWORD: "Reinicia la contraseña de tu usuario de red",
  },
  USER_GROUPS: {
    GROUP: "Grupo",
    NAME: "Nombre",
    ENTER_GROUP_NAME: "Introduzca nombre de grupo",
    IDENTIFIER: "Identificador de grupo",
    IDENTIFIER_EXAMPLE: "p.ej: Finanzas",
    NEW_GROUP: "Añadir Grupo",
    EDIT_GROUP: "Editar grupo",
    COMMENTS: "Comentarios",
    USERS_LIST: "Lista de usuarios",
    GROUP_CREATED_SUCCESS: "Grupo de usuarios creado correctamente",
    GROUP_CREATED_ERROR: "Error al crear grupo de usuarios",
    GROUP_UPDATED_SUCCESS: "Grupo de usuarios actualizado correctamente",
    GROUP_UPDATED_ERROR: "Error al actualizar grupo de usuarios",
    GROUP_DELETED_SUCCESS: "Grupo de usuarios borrado correctamente",
    GROUP_DELETED_ERROR: "Error al borrar grupo de usuarios",
    NO_USERS: "Sin usuarios",
    LOAD_ERROR: "Error al cargar Grupos de Usuarios",
  },
  OBJECT_MODAL: {
    ADD: "Agregar",
    NETWORK: "Red",
    SERVICE: "Servicio",
    CANCEL: "CANCELAR",
  },
  LOGS: {
    LOGS: "Logs",
    TIME: "Hora",
    BLADE: "Funcionalidad",
    ACTION: "Acción",
    ORIGIN: "Firewall",
    SOURCE: "Origen",
    SOURCE_NAME: "Nombre del origen",
    SOURCE_PORT: "Puerto de origen",
    DESTINATION: "Destino",
    DESTINATION_NAME: "Nombre del destino",
    SERVICE: "Servicio",
    SERVICE_DESCRIPTION: "Descripción del servicio",
    ACCESS_RULE: "Regla de acceso",
    ACCESS_RULE_NAME: "Nombre de la regla de acceso",
    POLICY_NAME: "Nombre de directiva",
    DESCRIPTION: "Descripción",
    LAST_HOUR_FILTER: "Ultima hora",
    TODAY_FILTER: "Hoy",
    LAST_24_HOURS_FILTER: "Últimas 24 horas",
    YESTERDAY_FILTER: "Ayer",
    THIS_WEEK_FILTER: "Esta semana",
    LAST_7_DAYS_FILTER: "Los últimos 7 días",
    THIS_MONTH_FILTER: "Este mes",
    LAST_14_DAYS_FILTER: "Últimos 14 días",
    LAST_30_DAYS_FILTER: "Últimos 30 días",
    ALL_TIME_FILTER: "Todo el tiempo",
    CUSTOM_FILTER: "Personalizada",
    TOP: "Tops",
    START_DATE: "Fecha de inicio",
    END_DATE: "Fecha final",
    START_DATE_REQUIRED: "Fecha de inicio requerida",
    SET: "Colocar",
    TOP_SOURCES: "Orígenes",
    TOP_DESTINATIONS: "Destinos",
    TOP_SERVICES: "Servicios",
    TOP_ACTIONS: "Acciones",
    TOP_BLADES: "Funcionalidades",
    TOP_ORIGINS: "Firewalls",
    TOP_USERS: "Usuarios",
    TOP_APPLICATIONS: "Aplicaciones",
    START_DATE_RESTRICTION: "Elija una fecha de inicio válida",
    END_DATE_RESTRICTION: "Elija una fecha de finalización válida",
    TOPS_ERROR: "Error al cargar tops",
    LOGS_ERROR: "Error al cargar logs",
    LOG_DETAILS: "Detalles del log",
    LOG_INFO: "Información del log",
    TRAFFIC: "Tráfico",
    POLICY: "Política",
    THREAT: "Amenaza",
    PROTOCOL: "Protocolo",
    PROTOCOL_NAME: "Nombre de protocolo",
    PROTECTION: "Protección",
    SOURCE_OS: "Sistema operativo de origen",
    DETECTED_ON_OS: "Detectado en el sistema operativo",
    FILE_NAME: "Nombre del archivo",
    MALWARE_ACTION: "Acción del malware",
    DOWNLOAD_FILE: "Descargar archivo",
    QUERY_SYNTAX: "Sintaxis de la consulta",
    QUERY_TITLE: "beSafe Premium Query Language",
    SET_CUSTOM_TIME: "Establecer hora personalizada",
    TIME_PERIOD: "Periodo de tiempo",
    CLEAR_SEARCH: "Borrar búsqueda",
    APPLICATION_SITE: "Aplicación / Sitio",
    APPLICATION_RISK: "Riesgo de aplicación",
    APPLICATION_NAME: "Nombre de la aplicación",
    APPLICATION_CATEGORY: "Categoría de aplicación",
    PRIMARY_CATEGORY: "Categoría primaria",
    APPLICATION_DESCRIPTION: "Descripción de la aplicación",
    CLIENT_TYPE: "Tipo de cliente",
    ADDITIONAL_CATEGORIES: "Categorías adicionales",
    ATTACK_NAME: "Ataque",
    RESOURCE: "Recurso",
    DATA_TYPE: "Tipo de datos",
    IKE: "Ike",
  },
  ADMIN: {
    ADMIN: "Administración",
    USERS: "Administradores",
    SESSIONS: "Sesiones",
    CONFIG: "Configuración general",
    SYSLOG: "Logs del sistema",
    REVISIONS: "Sesiones publicadas",
    WEBHOOKS: "Webhooks"
  },
  SESSIONS: {
    NAME: "Nombre",
    PENDING_CHANGES: "Cambios Pendientes",
    IN_WORK: "Activo",
    ORIGIN: "Origen",
    YES: "Sí",
    NO: "No",
    DELETE_ALL: "Descartar todos los cambios y sesiones",
    DELETE: "Descartar cambios y desconectar",
    CONFIRM_DELETE: "¿Está seguro de que desea descartar los cambios de esta sesión y desconectarla?",
    CONFIRM_DELETE_ALL: "¿Está seguro de que desea descartar los cambios de todas las sesiones y desconectarlas?",
    DELETE_SUCCESS: "Session borrada correctamente",
    DELETE_ALL_SUCCESS: "Todas las sesiones borradas correctamente",
    REFRESH: "Refrescar tabla",
    LOCKED_OBJECTS: "Objetos bloqueados",
    PUBLISH_TIME: "Fecha de publicación",
    REVERT: "Revertir",
    CHANGES: "Cambios",
    CONFIRM_REVERT: "¿Está seguro de que desea revertir los cambios a la sesión seleccionada?",
    REVERT_SUCCESS: "Cambios revertidos con éxito"
  },
  FIREWALLS: {
    FIREWALLS: "Firewalls",
    CLUSTERS: "Clústers",
    FIREWALLS_CLUSTERS: "Firewalls y Clústers",
    ADD_FIREWALL: "Añadir Firewall",
    EDIT_FIREWALL: "Editar Firewall",
    ADD_CLUSTER: "Añadir Cluster",
    EDIT_CLUSTER: "Editar Cluster",
    TYPE: "Tipo",
    STATUS: "Estado",
    NAME: "Nombre",
    IP: "IP",
    VERSION: "Versión",
    PASSWORD: "Contraseña de uso único",
    HARDWARE: "Hardware",
    CPU: "CPU",
    MEMORY: "Memoria",
    ACTIVE_BLADES: "Funcionalidades activas",
    LOAD_ERROR: "Error al cargar Firewalls",
    INTERFACES: "Interfaces",
    VPN: "VPN",
    VPN_SETTINGS: "Ajustes VPN",
    AUTOMATIC: "Automático",
    MANUAL: "Manual",
    VPN_DOMAIN_ADDRESSES: "Direcciones del dominio VPN",
    FIREWALL_CREATE_SUCCESS: "Firewall creado correctamente",
    FIREWALL_CREATE_ERROR: "Error al crear Firewall",
    FIREWALL_UPDATE_SUCCESS: "Firewall editado correctamente",
    FIREWALL_UPDATE_ERROR: "Error al editar Firewall",
    FIREWALL_DELETE_SUCCESS: "Firewall borrado correctamente",
    FIREWALL_DELETE_ERROR: "Error al borrar Firewall",
    LICENSE_BLADENAME: "Nombre",
    LICENSE_STATUS: "Estado",
    EXPIRATION_DATE: "Caducidad",
    LICENSE_ADDITIONAL_INFO: "Información Adicional",
    BLADES: "Funcionalidades",
    RESET_SIC: "Resetear ECS",
    CONFIRM_RESET_SIC: "¿Desea resetear el ECS del Firewall?",
    ADD_INTERFACE: "Nueva interfaz",
    EDIT_INTERFACE: "Editar interfaz",
    STATE: "Estado",
    LINK_STATE: "Estado Enlace",
    INTERFACES_LOAD_ERROR: "Error al cargar las interfaces",
    POLICY_INSTALLED: "Política instalada",
    HELP_TITLE: "Ayuda de creación de Firewall",
    ROUTES: "Rutas",
    ROUTE_DESTINATION: "IP Destino",
    ROUTE_MASK: "Máscara",
    ROUTE_GATEWAY: "IP Gateway",
    ROUTE_PRIORITY: "Prioridad",
    ROUTES_LOAD_ERROR: "Error al cargar rutas",
    ROUTE_DELETE_SUCCESS: "Ruta eliminada con éxito",
    IP_INVALID: "La IP es inválida",
    MASK_INVALID: "La máscara no es un número válido",
    PRIORITY_INVALID: "La prioridad no es un número válido",
    ROUTE_CREATED_SUCCESS: "Ruta creada con éxito",
    ROUTE_UPDATED_SUCCESS: "Ruta actualizada con éxito",
    INTERFACE_DELETE_SUCCESS: "Interfaz eliminada con éxito",
    EDIT_ROUTE: "Editar Ruta",
    ADD_ROUTE: "Añadir Ruta",
    CONFIRM_ROUTE_DELETE: "¿Desea eliminar la ruta?",
    INTERFACE_CREATED_SUCCESS: "Interfaz creada con éxito",
    INTERFACE_UPDATED_SUCCESS: "Interfaz actualizada con éxito",
    INTERFACE_TYPE: "Tipo de interfaz",
    INTERFACE_VLAN_ID: "Id de Vlan",
    INTERFACE_IP: "IP",
    INTERFACE_MASK: "Máscara",
    INTERFACE_MONITOR_MODE: "Modo monitor",
    VLAN_ID_INVALID: "Vlan ID debe ser un número entero válido",
    PHYSICAL_INTERFACE: "Interfaz física",
    CONFIRM_INTERFACE_DELETE: "¿Desea eliminar la interfaz?"
  },
  CLUSTERS: {
    CLUSTERS: "Clústers",
    STATUS: "Estado",
    NAME: "Nombre",
    IP: "IP",
    VPN: "VPN",
    VERSION: "Versión",
    HARDWARE: "Hardware",
    ACTIVE_BLADES: "Funcionalidades activas",
    CLUSTER_MODE: "Modo del clúster",
    ERROR_NAME_ZERO: "El nombre no puede contener la palabra 'zero'",
    INTERFACES: "Interfaces del clúster",
    ADD_INTERFACE: "Añadir interfaz al clúster",
    EDIT_INTERFACE: "Editar interfaz del clúster",
    INTERFACE_TYPE: "Tipo de interfaz",
    INTERFACE_IP: "Dirección IP de Cluster",
    INTERFACE_VIRTUAL_IP: "IP Virtual",
    INTERFACE_MASK: "Máscara de red",
    INTERFACE_NAME: "Nombre",
    INTERFACES_SPOOFING: "Anti-spoofing",
    INTERFACE_TOPOLOGY: "Topología",
    INTERFACE_ANTI_SPOOFING: "Anti-spoofing",
    MEMBER_IP: "Dirección IP de Miembro",
    MEMBERS: "Miembros",
    PASSWORD: "Contrasña de uso único",
    MEMBER: "Miembro del clúster",
    IP_INVALID: "La dirección IP no es válida",
    MASK_INVALID: "La máscara de red no es válida",
    IP_MEMBER_ERROR: "Uno o más miembros no tienen una dirección IP",
    MASK_MEMBER_ERROR: "Uno o más miembros no tienen una máscara de red",
    MAXIMUM_MEMBER: "El número máximo de miembros en un clúster es 4",
    MINIMUM_MEMBER: "El clúster debe tener mínimo un miembro",
    MEMBER_IP_INTERFACE_ERROR:
      "Uno o más miembros no tienen una dirección IP en la interfaz o la dirección es inválida",
    MEMBER_IP_INTERFACE_BLOCK_ERROR:
      "Uno o más miembros no tienen una dirección IP en el mismo bloque que la interfaz",
    INTERFACES_SYNC_VALIDATION:
      "Al menos una interfaz del clúster debe ser de tipo Sincronización ó Cluster + Sincronización",
    ADD_MEMBER: "Añadir miembro al clúster",
    EDIT_MEMBER: "Editar miembro del clúster",
    DELETE_MEMBER: "Borrar miembro del clúster",
    MEMBER_ADD_SUCCESS: "Miembro del clúster añadido correctamente",
    MEMBER_ADD_ERROR: "Error al añadir miembro al clúster",
    MEMBER_EDIT_SUCCESS: "Miembro del clúster editado correctamente",
    MEMBER_EDIT_ERROR: "Error al editar miembro del clúster",
    MEMBER_DELETE_SUCCESS: "Miembro del clúster borrado correctamente",
    MEMBER_DELETE_ERROR: "Error al borrar miembro del clúster",
    LOAD_ERROR: "Error al cargar Clústers",
    CLUSTER_CREATE_SUCCESS: "Clúster creado correctamente",
    CLUSTER_CREATE_ERROR: "Error al crear Clúster",
    CLUSTER_UPDATE_SUCCESS: "Clúster editado correctamente",
    CLUSTER_UPDATE_ERROR: "Error al editar Clúster",
    CLUSTER_DELETE_SUCCESS: "Clúster borrado correctamente",
    CLUSTER_DELETE_ERROR: "Error al borrar Clúster",
    CLUSTER_TASK_FINISHED:
      "La tarea de guardar el Clúster ha finalizado correctamente",
    CLUSTER_TASK_ERROR:
      "La tarea de guardar el Clúster no finalizó correctamente",
    CONFIRM_CLOSE_MODAL:
      "Si has hecho cambios, no se guardarán al menos que envíes el formulario. ¿Está seguro de que desea cerrar de todas formas?",
    HELP_TITLE: "Ayuda de creación de Cluster"
  },
  LICENSES: {
    LICENSES: "Licencias",
    LICENSE: "Licencia",
    FIREWALL: "Firewall",
    EXPIRATION_DATE: "Fecha de Caducidad",
    ADD_LICENSE: "Añadir licencia",
    EDIT_LICENSE: "Editar licencia",
    DELETE_LICENSE: "Borrar licencia",
    LICENSE_ADD_SUCCESS: "Licencia añadida correctamente",
    LICENSE_ADD_ERROR: "Error al añadir licencia",
    LICENSE_EDIT_SUCCESS: "Licencia actualizada correctamente",
    LICENSE_EDIT_ERROR: "Error al actualizar licencia",
    LICENSE_DELETE_SUCCESS: "Licencia borrada correctamente",
    LICENSE_DELETE_ERROR: "Error al borrar licencia",
    LOAD_ERROR: "Error al cargar licencias",
    HOST: "Host",
    FEATURES: "Características",
    DEVICE: "Dispositivo",
    SIGNATURE: "Firma",
    ATTACH: "Asociar Licencia",
    DETACH: "Desasociar Licencia",
    ATTACHED: "Licencias asociadas",
    UNATTACHED: "Licencias no asociadas",
    LICENSE_ATTACH_SUCCESS: "Licencia asociada con éxito",
    LICENSE_ATTACH_ERROR: "Error al asociar licencias",
    LICENSE_DETACH_SUCCESS: "Licencia desasociada con éxito",
    LICENSE_DETACH_ERROR: "Error al desasociar licencias",
    CONFIRM_DETACH: "¿Desea desasociar la licencia del dispositivo?",
    CONFIRM_ATTACH: "¿Desea asociar la licencia al dispositivo?",
    LOAD_DEVICE_ERROR: "Error al cargar los dispositivos",
    GET_ERROR: "Error al obtener las licencias de los dispositivos",
    HELP_TITTLE: "Como añadir una licencia",
    POLICY_INSTALLED: "Política instalada"
  },
  SYSLOG: {
    NAME: "Nombre",
    SERVER: "Servidor destino",
    PORT: "Puerto destino",
    PROTOCOL: "Protocolo",
    FORMAT: "Formato",
    ENCRYPTED: "Cifrado",
    CA_CERT: "Certificado CA",
    CLIENT_CERT: "Certificado cliente",
    CLIENT_SECRET: "Secreto del cliente",
    NAME_EXISTS: "El nombre ya existe",
    INVALID_PEM_FILE: "Archivo pem requerido",
    INVALID_PKCS_FILE: "Archivo Pkcs#12 requerido",
    NEW_SYSLOG: "Nuevo syslog",
    UPDATE_SYSLOG: "Actualizar syslog",
    CREATE_SUCCESS: "Syslog creado correctamente",
    UPDATE_SUCCESS: "Syslog actualizado correctamente",
    DELETE_SUCCESS: "Syslog eliminado correctamente",
    CONFIRM_DELETE: "¿Está seguro de que desea eliminar este syslog?",
    HELP_TITLE: "Ayuda de syslog",
    BY_CLIENT: "Syslogs por cliente",
    PORT_BY_CLIENT: "Puerto para el cliente",
    INVALID_PORT: "Puerto no válido",
    UNIQUE_PORT: "El puerto debe ser único",
  },
  REPORTS: {
    TITLE: "Informes",
    REPORT: "Informe",
    FILTER: "Período de tiempo",
    GENERATE: "Generar informe",
    DOWNLOAD: "Descargar",
    SEND: "Enviar",
    APP_FILTER: "Filtro de aplicación y Urls",
    NETWORK_ACTIVITY: "Actividad de Redes",
    NETWORK_SECURITY: "Seguridad de Redes",
    SECURITY_AD: "Seguridad Avanzada",
    SECURITY_STATS: "Estadísticas de Seguridad",
    THREAT_EMULATION: "Emulación de Amenazas",
    THREAT_EXTRACTION: "Extracción de Amenazas",
    THREAT_PREVENTION: "Prevención de Amenazas",
    USER_ACTIVITY: "Actividad de Usuarios",
    TODAY: "Hoy",
    YESTERDAY: "Ayer",
    THIS_WEEK: "Esta semana",
    THIS_MONTH: "Este mes",
    THIS_YEAR: "Este año",
    LAST_HOUR: "Última hora",
    LAST_24: "Últimas 24 horas",
    LAST_7: "Últimos 7 días",
    LAST_WEEK: "Última semana",
    LAST_14: "Últimos 14 días",
    LAST_30: "Últimos 30 días",
    LAST_MONTH: "Último mes",
    LAST_365: "Últimos 365 días",
    LAST_YEAR: "Último año",
    ERROR: "Ha ocurrido un error al generar el informe",
    SERVICE_DOWN: "El servicio de informes no responde",
    SCHEDULE: "Programar un informe",
    TIME_SCHEDULE: "Programar",
    EVERY: "Cada",
    DAILY: "Día",
    WEEKLY: "Semana",
    MONTHLY: "Mes",
    YEARLY: "Año",
    AT: "A las",
    ON: "El",
    OF: "De",
    MONDAY: "Lunes",
    TUESDAY: "Martes",
    WEDNESDAY: "Miércoles",
    THURSDAY: "Jueves",
    FRIDAY: "Viernes",
    SATURDAY: "Sábado",
    SUNDAY: "Domingo",
    JANUARY: "Enero",
    FEBRUARY: "Febrero",
    MARCH: "Marzo",
    APRIL: "Abril",
    MAY: "Mayo",
    JUNE: "Junio",
    JULY: "Julio",
    AUGUST: "Agosto",
    SEPTEMBER: "Septiembre",
    OCTOBER: "Octubre",
    NOVEMBER: "Noviembre",
    DECEMBER: "Diciembre",
    NAME: "Nombre",
    MAIL: "Email",
    SUBJECT: "Asunto",
    PROGRAMMED: "Programado",
    TIMEZONE: "Zona horaria",
    UPDATE_ERROR: "Error al actualizar información del informe programado",
    NAME_EXISTS_ERROR: "El nombre ya existe",
    CREATE_ERROR: "Error al crear informe programado",
    CREATED_SUCCESS: "Informe programado creado con éxito",
    UPDATED_SUCCESS: "Informe programado actualizado con éxito",
    DELETED_SUCCESS: "Informe programado eliminado con éxito",
    DELETE_ERROR: "Error al eliminar informe programado",
    REPORTS_ERROR: "Error al obtener los informes programados",
    UPLOAD_SUCCESS: "Logo subido correctamente",
    UPLOAD_LOGO: "Subir logo",
    LOGO: "Logo del informe",
    INVALID_IMAGE_TYPE: "El archivo subido no es una imagen (jpg, png...)",
    INVALID_IMAGE_SIZE: "El tamaño de la imagen no es válido",
    LOGO_HELP_TITLE: "Ayuda de subida del logo",
    GENERATING: "Generando...",
    SUCCESS_GENERATE: "Informe generado correctamente",
    CLIENT: "Cliente",
    ALL_CLIENTS: "Todos los clientes",
    CANCEL_REPORT_GENERATION: "La generación del informe sera cancelado si sale antes de que se complete el informe"
  },
  UPDATABLE_OBJECTS: {
    NEW_UPDATABLE_OBJECT: "Añadir objecto actualizable",
    ERROR: "Error al obtener objectos actualizables",
    ERROR_CREATING: "Error al crear objectos actualizables",
    ERROR_CHILDREN: "Error al obtener los hijos",
    CONFIRM_CREATE: "¡¡Atención!! Todos los cambios no guardados se perderán. ¿Desea continuar?",
    CREATED_SUCCESS: "Objetos actualizables creados con éxito",
    REPOSITORY_DOWNLOAD_SUCCESS: "Repositorio descargado con éxito",
    ERROR_DOWNLOADING: "Error al descargar el repositorio",
    DOWNLOAD_REPOSITORY: "Descargar objetos de repositorio",
    DOWNLOAD_REPOSITORY_TIP: "La lista está vacía, para llenarla use el botón de la barra de título para descargar el repositorio"
  },
  WEBHOOKS: {
    CREATE_SUCCESS: "Webhook creado con éxito",
    UPDATE_SUCCESS: "Webhook actualizado con éxito",
    ERROR_CREATE: "Error al crear webhook",
    ERROR_UPDATE: "Error al actualizar webhook",
    NEW: "Nuevo webhook",
    UPDATE: "Actualizar webhook",
    DELETE: "Borrar webhook",
    NAME: "Nombre",
    TYPE: "Tipo",
    PATH: "Path",
    TUNNEL_DOWN: "Aviso de túnel caído",
    DELETE_SUCCESS: "Webhook eliminado con éxito",
    ERROR_DELETE: "Error al eliminar webhook",
    EXPECTED_BODY: "Cuerpo esperado"
  },
  MAINTENANCE: {
    MESSAGES: "Mensajes de sistema",
    MESSAGES_ERROR: "Error al obtener mensajes",
    DELETE_ERROR: "Error al eliminar mensaje",
    DELETED_SUCCESS: "Mensaje de sistema eliminado correctamente",
    NAME: "Nombre",
    MESSAGE: "Mensaje",
    UNTIL: "Hasta",
    CREATED_SUCCESS: "Mensaje de sistema creado correctamente",
    NAME_EXISTS_ERROR: "El nombre ya existe",
    CREATE_ERROR: "Error al crear el mensaje de sistema",
    UPDATED_SUCCESS: "Mensaje de sistema actualizado correctamente",
    UPDATE_ERROR: "Error al actualizar el mensaje de sistema",
    UPDATE_MESSAGE: "Actualizar mensaje de sistema",
    SYSTEM_MESSAGE: "Crear mensaje de sistema",
    SEND: "Enviar",
    CREATE: "Crear"
  }
};

export default flatten(messages);
